<template>
  <div class="container">
    <a class="btn__back" @click="$router.go(-1)">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <h2 class="leading">Answer your security question</h2>
    <p class="sm_text">Choose one question to answer</p>
    <br />

    <form
      class="form"
      @submit.prevent="submitForm"
      novalidate="true"
      v-loading="loadingTwo"
    >
      <div class="form__group">
        <br />

        <!--  -->

        <label for="question" class="form__label">
          <select
            name="question"
            id="question"
            class="form__input form__select"
            v-model="$v.form.selectedQuestionId.$model"
            :class="{
              'is-invalid': submitted && $v.form.selectedQuestionId.$error,
            }"
          >
            <option disabled value="">Security Questions</option>
            <option
              v-for="(question, index) in questions"
              :key="question.questionId"
              :value="index"
            >
              {{ question.question }}
            </option>
          </select>
        </label>
        <small class="error" v-if="submitted && !$v.form.selectedQuestionId.required"
          >Security Question is required</small
        >
        <br />
        <br />

        <label for="answer" class="form__label">
          <input
            type="password"
            id="answer"
            name="answer"
            placeholder="Input Answer"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model="$v.form.answer.$model"
            :class="{ 'is-invalid': submitted && $v.form.answer.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.answer.required"
          >Answer is required</small
        >
        <br />
        <br />
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      submitted: false,
      loadingTwo: false,
      questions: [],
      form: {
        selectedQuestionId: "",
        answer: "",
      },
    };
  },
  validations: {
    form: {
      answer: { required },
      selectedQuestionId: { required },
    },
  },
  computed: mapState(["forgetPasswordData"]),
  beforeMount() {
    if (this.forgetPasswordData == null) {
      this.$router.push("/device-change/account");
      return;
    }

    this.loadingTwo = true;
    let payload = {
      userid: this.forgetPasswordData.userId,
    };

    api
      .getQuestionByUserId(payload)
      .then((response) => {
        this.loading = false;
        let responseStatus = response.Status;
        let responseMessage = response.Message;

        if (responseStatus) {
          this.questions = response.Data;
          this.loadingTwo = false;
        } else {
          this.$message({
            showClose: true,
            message: `${
              responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
            }`,
            type: "error",
            duration: 10000,
          });
        }
      })
      .catch((error) => {
        this.loading = false;
        this.$message({
          showClose: true,
          message: `${error}`,
          type: "error",
          duration: 10000,
        });
      });
  },
  methods: {
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      this.questions[this.form.selectedQuestionId].answer = this.form.answer;
      let payload = this.questions[this.form.selectedQuestionId];

      this.loading = true;

      api
        .VerifyQuestionByUserID(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Security Question Validated Successfully`,
              type: "success",
              duration: 3000,
            });
            setTimeout(() => {
              this.$router.push("/device-change/pin");
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.sm_text {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  color: #000000;
  padding-bottom: 0.5rem;
}
.question {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  letter-spacing: 0.05em;
  color: #000000;
}
</style>
